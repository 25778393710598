.contacts {
    &-list {
        display: flex;
        flex-wrap: wrap;
    }
    &-item {
        cursor: pointer;
        font-size: 12px;
        height: 68px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #979797;
        width: 100%;
        a {
            color: #424242;
        }
        &:hover {
            background: #efefef;
        }
        &--short {
            width: 50%;
            &:nth-child(3n + 4) {
                display: flex;
                align-items: center;
                &:before {
                    margin-right: 15px;
                    width: 1px;
                    height: 28px;
                    background: #979797;
                    content: '';
                    display: block;
                }
            }
        }
    }
    &-link {
        color: #0830b4;
    }
}
