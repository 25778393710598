.content-wrapper {
    padding: 30px 15px;
}

.sp-wrapper {
    height: calc(100vh - 76px);
    display: flex;
    flex-direction: column;
    width: 375px;
}

.in-modal {
    height: 100%;
    width: 371px;
    padding: 0 15px 30px;
}
