.button {
    height: 35px;
    width: 100%;
    display: flex;
    background: transparent;
    align-items: center;
    color: #424242;
    justify-content: center;
    border: 1px solid #979797;
    border-radius: 2px;
    font-weight: bold;
    font-size: 12px;
    img {
        margin-left: 10px;
    }
}
