.mobile-nav {
    padding: 17px 23px 0;
    &-group {
        display: flex;
        justify-content: space-between;
        padding-bottom: 17px;
        a {
            width: 45%;
        }
    }
    @media (min-width: 768px) {
        display: none;
    }
}
