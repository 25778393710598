.header {
    font-family: 'Fira Sans', sans-serif;

    &-wrapper,
    &-logo,
    &-lang {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-wrapper {
        justify-content: flex-start;
        background: #fff;
        position: sticky;
        top: 0;
        z-index: 1;
        height: 75px;
        padding: 0 15px;
        border-bottom: 1px solid #979797;
    }
    &-logo {
        color: #424242;
        cursor: pointer;
        &:focus,
        &:hover {
            color: #424242;
            text-decoration: none;
        }
        div {
            font-size: 22px;
            margin-left: 15px;
            display: flex;
            align-items: center;
            &:before {
                margin-right: 15px;
                width: 1px;
                height: 28px;
                background: #979797;
                content: '';
                display: block;
            }
        }
    }
    &-lang {
        cursor: pointer;
        text-transform: capitalize;
        user-select: none;
        margin-left: auto;
        svg {
            margin-right: 3px;
        }
    }
}

nav {
    display: none;
    padding-left: 165px;
    div {
        cursor: pointer;
        padding: 0 7px;
        font-size: 14px;
        letter-spacing: 0.494118px;
    }
    @media (min-width: 768px) {
        display: flex;
    }
}
