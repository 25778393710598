body {
    font-family: 'Open Sans', sans-serif;
    color: #424242;
}

h2 {
    font-size: 24px;
    font-weight: normal;
    padding: 23px 0;
    letter-spacing: 0.847059px;
}

a {
    color: #424242;
    &:hover,
    &:focus,
    &:visited,
    &:active {
        text-decoration: none;
        color: #424242;
    }
}

.price-block {
    text-align: right;
    margin-top: auto;
    padding: 0 25px;
}
.prev-price {
    font-size: 18px;
    display: inline-flex;
    position: relative;

    &:after {
        content: '';
        display: block;
        height: 3px;
        width: 100%;
        background: #ff0000;
        position: absolute;
        left: 0;
        top: 13px;
        transform: rotate(-10deg);
    }
}
.price {
    font-size: 24px;
    padding-bottom: 30px;
}

.slick-slide:nth-child(1n + 2) {
    transform: translate(-20px, 0);
    transition: transform 0.5s ease;
}
.slick-current {
    transform: none !important;
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    padding-top: 35px;
    button {
        background: transparent;
        border: none;
        cursor: pointer;
        width: 15px;
        height: 15px;
        outline: none;
    }
    li {
        font-size: 0;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        border: 1px solid #d8d8d8;
        &.slick-active {
            background: #d8d8d8;
            border-color: #d8d8d8;
        }
    }
    li + li {
        margin-left: 10px;
    }
}

.home-left {
    width: 345px;
}
.home-right {
    display: none;
    @media (min-width: 768px) and (max-width: 850px) {
        height: 270px;
    }
}

.list-consult {
    list-style-type: disc;
    padding: 0 0 10px 20px;
}

@media (min-width: 768px) {
    .home {
        display: flex;
        &-left {
            flex: none;
        }
        &-right {
            display: block;
            margin-left: 50px;
        }
    }
}
