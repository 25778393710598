.card {
    width: 300px;
    height: 200px;
    border: 1px solid #979797;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 15px 18px;

    &-title {
        font-size: 24px;
        letter-spacing: 0.847059px;
    }
    &-date {
        font-size: 11px;
        letter-spacing: 0.388235px;
    }
    &-desc {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 11px;
    }
}
