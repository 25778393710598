.main {
    border-bottom: 1px solid #979797;
    padding-bottom: 13px;
    &-top {
        display: flex;
        align-items: center;
    }
    &-photo {
        border-radius: 50%;
        overflow: hidden;
        width: 80px;
        height: 80px;
        flex: none;
        img {
            filter: grayscale(1);
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    &-name {
        font-weight: bold;
        font-size: 24px;
        padding-left: 25px;
        width: 100px;
        line-height: 1.3;
    }
    &-qr-link {
        flex: none;
        align-self: baseline;
        margin-left: auto;
        @media (min-width: 768px) {
            display: none;
        }
    }
    &-close-icon {
        flex: none;
        cursor: pointer;
        align-self: flex-end;
    }
    &-qr {
        margin-top: 95px;
    }
    &-desc {
        padding: 25px 0 10px;
    }
    &-text {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.423529px;
        display: flex;
        padding: 3px 0;
    }
    &-place {
        font-size: 9px;
    }
}
